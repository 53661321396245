import { createGlobalStyle, css } from 'styled-components';

import { background, color, typography } from "./styles"

export const bodyStyles = css`
  color: ${color.textPrimary};
  font-family: ${typography.type.primary};
  font-size: ${typography.size.p1};
  background: ${background.bg};

  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${typography.weight.regular};
    margin: 0;
    padding: 0;
  }
  button,
  input,
  textarea,
  select {
    outline: none;
    font-family: ${typography.type.primary};
  }
  sub,
  sup {
    font-size: 0.8em;
  }
  sub {
    bottom: -0.2em;
  }
  sup {
    top: -0.2em;
  }
  b,
  em {
    font-weight: ${typography.weight.bold};
  }

  a {
    cursor: pointer;
    transition: color 150ms ease-out;
    outline: none;
    text-decoration: none;

    &:hover {
      color: ${color.textSecondary};
    }

    &:active {
      color: #d3d9e1;
    }
  }
  
  input[type="text"] {
   -moz-box-sizing:    border-box;
   -webkit-box-sizing: border-box;
    box-sizing:        border-box;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    ${bodyStyles}
  }
  
  main {
    background: #FFFFFF;
  }
`;
