import React from "react"
import styled, { css } from 'styled-components';
import { breakpoint, layout } from "../shared/styles"

const Container = styled.div`
  width: 100%;
  max-width: ${layout.width.maxWidth};
  margin: 0 ${layout.columnPaddingNormal}px;
  
  padding: ${layout.sectionPaddingTop} 0 ${layout.sectionPaddingBottom};
`;

const Wrapper = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  
  overflow: hidden;
  
  ${props => props.backgroundColor && css`background-color: ${props.backgroundColor};`}
`;

export default function Section({ children, backgroundColor, ...props }) {
  return (
    <Wrapper backgroundColor={backgroundColor}>
      <Container>
        {children}
      </Container>
    </Wrapper>
  )
}