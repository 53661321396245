import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { background, breakpoint, color, layout, pageMargins, typography } from "../shared/styles"

import LogoSVG from './../../assets/logo.svg';
import AppStoreBadgeSVG from '../../assets/app_store_badge.svg';
import PlayBadgeSVG from '../../assets/play_badge.svg';
import FacebookSVG from '../../assets/social/facebook.svg';
import InstagramSVG from '../../assets/social/instagram.svg';
import TwitterSVG from '../../assets/social/twitter.svg';
import Section from "./Section"

const Title = styled.div`
  display: block;
  font-size: ${typography.size.h4mobile}px;
  margin-bottom: 8px;
  font-weight: ${typography.weight.semibold};
  
  border-top: 1px solid #d3d9e1;

  @media (min-width: ${layout.viewportWidthMedium}px) {
    font-size: ${typography.size.h4}px;
    border: 0;
  }

  @media (max-width: ${layout.viewportWidthMedium}px) {
    position: relative;
    cursor: pointer;
    
    padding: 16px 0;
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 10px;
      margin: auto 0 auto auto;
      width: 28px;
      height: 28px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background: url(${(props) => (props.expanded ? '/images/chevron-down-icon.svg' : '/images/chevron-down-icon.svg')});
      transform: scaleY(-1);
    }
  }
`;

const LogotypeWrapper = styled(Link)`
  margin-bottom: 8px;
  display: block;
  
  svg {
    height: 28px;
    width: auto;
    display: block;
    transition: all 150ms ease-out;
    transform: translate3d(0, 0, 0);
    &:hover {
      transform: translate3d(0, -1px, 0);
    }
    &:active {
      transform: translate3d(0, 0, 0);
    }
  }
`;

const FooterLink = styled(Link)`
  font-size: ${typography.size.p2}px;
  color: ${color.textSecondary};
  weight: ${typography.weight.regular};
  
  &:hover {
    color: ${color.textPrimary};
  }
`;

const Text = styled.div`
  color: ${color.textSecondary};
`;

const Colophon = styled.div`
  a {
    vertical-align: top;
  }
`;

const Column = styled.div`
  > ${FooterLink} {
    display: block;
  }
  
  ${FooterLink} + ${FooterLink} {
    margin-top: 14px;
  }
`;

const HrWrapper = styled.div`
  hr {
    border-top: 1px solid #D3D9E1;
    margin: 24px 0;
    appearance: none;
    height: 0px;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }
  
  padding: 0 ${layout.columnPaddingNormal}px;
`;

const Upper = styled.div`
  display: grid;
  grid: auto/minmax(0,1fr);
  row-gap: 16px;
  
  @media (min-width: ${layout.viewportWidthMedium}px) {
    grid-template-columns: repeat(5, 1fr);
  }

  ${Colophon} {
    display: grid;
    flex-direction: column;
    row-gap: 8px;
    
    padding: 0 ${layout.columnPaddingNormal}px;
    
    width: 100%;
    @media (min-width: ${layout.viewportWidthMedium}px) {
      width: auto;
      max-width: 200px;
    }
  }
  ${Column} {
    width: 100%;
    //padding-top: 16px;
    
    padding: 0 ${layout.columnPaddingNormal}px;
  
    @media (min-width: ${layout.viewportWidthMedium}px) {
      //padding: 20px 16px 0 0;
      //border: 0;
    }
  }
`;

const SocialList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  
  justify-content: flex-start;
  
  li {
    display: inline-block;
    
    a {
      color: ${color.textSecondary};
      
      &:active, &:hover {
        color: ${color.textPrimary};
      }
    }
    
    svg {
      width: 24px;
      height: 24px;
    }
  }
  
  li + li {
    margin-left: 32px;
  }
`;

const Lower = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  
  padding: 0 ${layout.columnPaddingNormal}px;
  
  @media (min-width: ${breakpoint}px) {
    flex-direction: row;
    
    div {
      margin-bottom: 0;
    }
  }
  
  div {
    color: ${color.textSecondary};
    font-size: 15px;
    line-height: 24px;
    display: flex;
    flex: 1;
    padding-right: ${layout.columnPaddingNormal}px;
    margin-bottom: 20px;
  }
`;

export default function Footer({ ...props }) {
  return (
    <Section backgroundColor={background.bg}>
      <Upper>
        <Colophon>
          <LogotypeWrapper href="/">
            <LogoSVG height="30" />
          </LogotypeWrapper>
          <AppStoreBadgeSVG width="120" height="40" />
          <PlayBadgeSVG width="120" height="40" />
        </Colophon>
        <Column>
          <Title>Learn more</Title>
          <FooterLink href="/how-it-works">How it Works</FooterLink>
          <FooterLink href="/card">Card</FooterLink>
          <FooterLink href="/faq">FAQ</FooterLink>
        </Column>
        <Column>
          <Title>Company</Title>
          <FooterLink href="/about">About us</FooterLink>
          <FooterLink href="/careers">Careers</FooterLink>
          <FooterLink>Blog</FooterLink>
          <FooterLink href="/press">Press</FooterLink>
        </Column>
        <Column>
          <Title>Support</Title>
          <FooterLink href="/help">Help Center</FooterLink>
          <FooterLink href="/contact">Contact us</FooterLink>
          <FooterLink href="/status">System Status</FooterLink>
        </Column>
        <Column>
          <Title>Legal</Title>
          <FooterLink href="/terms-of-service">Terms of Service</FooterLink>
          <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
        </Column>
      </Upper>
      <HrWrapper>
        <hr />
      </HrWrapper>
      <Lower>
        <div>
          &copy; 2020 Splyt{/* – Splyt card is issued by _ Bank, member FDIC, pursuant to a license
          from Mastercard International, Inc.*/}
        </div>
        <SocialList>
          <li>
            <a href="https://www.facebook.com/Splyt-112261870563379">
              <FacebookSVG />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/getsplyt">
              <InstagramSVG />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/splytapp">
              <TwitterSVG />
            </a>
          </li>
        </SocialList>
      </Lower>
    </Section>
  );
}
